import styled from 'styled-components';

export const TranscriptWrapper = styled.section`
  /* padding: 100px 0 0 0;*/
`;
TranscriptWrapper.displayName = 'TranscriptWrapper';

export const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
  col: {
    width: 1,
    pb: ['10px', '10px', '20px', '20px'],
    // pl: ['10px', '10px', '20px', '20px'],
    // pr: ['10px', '10px', '20px', '20px'],
    // pr: ['10px', '10px', '20px', '20px'],
  },
  contentCol: {
    // width: [3/4, 3/4, 2/3, 2/3],
    width: [1, 1, 3/4, 3/4],
    pb: ['10px', '10px', '20px', '20px'],
    pl: ['10px', '10px', '20px', '20px'],
    pr: ['10px', '10px', '20px', '20px'],
    // pr: ['10px', '10px', '20px', '20px'],
  },

}

// BlogPost.defaultProps = {
//   rowCentered: {
//     textAlign: 'center',
//   },
//   row: {
//     flexBox: true,
//     flexWrap: 'wrap',
//     alignItems: 'center',
//     justifyContent: 'center',
//     mt: '50px',
//     mb: '50px',
//   },
//   contentWrapper: {
//     width: ['100%', '100%', '75%', '75%', '75%'],
//     mb: '40px',
//   },
//   blogTitle: {
//     fontSize: ['24px', '32px', '40px', '42px', '46px'],
//     fontWeight: '700',
//     color: '#000',
//     letterSpacing: '-0.025em',
//     mb: ['20px', '25px', '25px', '25px', '25px'],
//     lineHeight: '1.2',
//     textAlign: 'center',
//   },
//   bodyText: {
//     // fontSize: ['24px', '32px', '40px', '42px', '46px'],
//     // fontFamily: 'Raleway',
//     fontSize: ['24px'],
//     fontWeight: '400',
//     color: '#000',
//     // letterSpacing: '-0.025em',
//     mb: ['20px', '25px', '25px', '25px', '25px'],
//     lineHeight: '1.5',
//     textAlign: 'left',
//   },
// };
export default TranscriptWrapper
