// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
// import Box from 'reusecore/src/elements/Box';
// STYLING
// LOGIC AND HANDLERS
// NPM MODULES
import ReactAudioPlayer from 'react-audio-player';

// Audio player
export const AudioPlayer = ({ episode }) => {
  if (episode) {
    return (
      <>
        <ReactAudioPlayer
          style={{
            height: '30px',
            width: '100%',
          }}
          src={episode.audio}
          title={episode.title}
          artist={episode.publisher}
          controls
        />
      </>
    );
  } else {
    return <></>;
  }
};
