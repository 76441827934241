// REACT AND GATSBY IMPORTS
import React, {useState} from 'react';
// COMPONENTS
import DefaultLayout from 'gatsby-landing/src/components/DefaultLayout';
import Transcript from 'common/src/components/Transcript'

const TranscriptPage = () => {
  // const titleCb = (title) => {return title}
  const [title, updateTitle] = useState('')
  return (
    <DefaultLayout seo={{ title }}>
      <Transcript titleCb={updateTitle}/>
    </DefaultLayout>
  );
};

export default TranscriptPage;
