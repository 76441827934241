// REACT AND GATSBY
import React, { useEffect, useState } from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import TsxHeading from './Heading'
import TsxContent from './Content'
// STYLING
import { TranscriptWrapper, Styles } from './transcript.styles'
// LOGIC AND HANDLERS
import { pageTimeout } from '../../../../api/BrowserAPI'
import { getTsxObj }from './handlers'; // Code to handle user I/O
// NPM MODULES
import { trackPromise } from 'react-promise-tracker';

// Transcript component
const Transcript = ({titleCb}) => {
  const [tsxObj, updateTsxObj] = useState(null)

  useEffect(() => {
    async function initialLoad() {
      let transcriptObj = null
      pageTimeout( () => { return transcriptObj == null })
      transcriptObj = await trackPromise ( getTsxObj() )
      if (transcriptObj) {
        updateTsxObj(transcriptObj)
        titleCb(transcriptObj.episode.title)
      }
    }
    initialLoad()
  }, []);

  const { row, col } = Styles

  return (
    <TranscriptWrapper>
      {(tsxObj) &&
      <>
      <TsxHeading episode={tsxObj.episode}/>
      <TsxContent segments={tsxObj.segments}/>
      <Box {...row}>
        <Button title="<< BACK" onClick={()=>window.history.back()}/>
      </Box>
      </>
      }
    </TranscriptWrapper>
  );
};


export default Transcript;
