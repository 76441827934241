// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
// import Text from 'reusecore/src/elements/Text';
// import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
// import Image from 'reusecore/src/elements/Image';
// import Container from 'common/src/components/UI/Container';
import Spinner from 'reusecore/src/elements/Spinner'
import { AudioPlayer } from 'common/src/components/JobWidgets/AudioPlayer';
import EpisodeTile from 'common/src/components/InfoTile/episodeTile'

// STYLING
import { Styles } from './transcript.styles'
// LOGIC AND HANDLERS
// import InputHandler from './handlers'; // Code to handle user I/O
// NPM MODULES

// Transcript Heading component
const TsxHeading = ({ episode }) => {
  const { row, col } = Styles
  return (
  <Box {...row}>
    {/* EPISODE TITLE */}
    <Box {...col}>
      <center>
        <Spinner />
        <h1>{episode.title}</h1>
      </center>
    </Box>
    {/* EPISODE TILE AND AUDIO PLAYER */}
    <Box {...row}>
      <Box {...col}>
        <EpisodeTile tileStyle="large" episode={episode} />
        <AudioPlayer episode={episode} />
      </Box>
    </Box>
  </Box>
  );
};


export default TsxHeading;
