// REACT AND GATSBY
import React from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
// import Text from 'reusecore/src/elements/Text';
// import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
// import Image from 'reusecore/src/elements/Image';
// import Container from 'common/src/components/UI/Container';
// STYLING
import { Styles } from './transcript.styles'
// LOGIC AND HANDLERS
// import InputHandler from './handlers'; // Code to handle user I/O
// NPM MODULES
import Moment from 'moment'

// Transcript Content component
const TsxContent = ({ segments }) => {
  const { row, col, contentCol } = Styles

  const transcriptContent = ( segments ) && 
  <>
  {segments.map( segment => {
    const {content, speaker_label, start_time, end_time } = segment
    // Format start time of segment
    const startTime = Moment.utc(segment.start_time*1000).format('HH:mm:ss')
    // Remove speaker index
    const speaker = speaker_label.replace(/(^[0-9]+ )/, "")

    return (
      <Box {...row}>
        <Box {...col}>
          <h5>{speaker + ' ('+startTime+'):'}</h5>
          <p>{content}</p>
        </Box>
      </Box>
      )
  })}
  </>

  return (
    <Box {...row}>
      <Box {...contentCol}>
        {transcriptContent}
      </Box>
    </Box>
  );
};

export default TsxContent;
