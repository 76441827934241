/**
 * Logic handler for transcript component
 */
// CUSTOM APIs
import fetch from 'cross-fetch'
// import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
import { getUrlParams } from '../../../../api/BrowserAPI';
// import { getUserId, pageTimeout } from '../../../../api/UserLoginAPI';
// NPM MODULES
// import moment from 'moment';
// Get episode transcript data from S3
export const getTsxObj = async () => {
  const { id } = getUrlParams()
  const url = "https://podstacks.s3.amazonaws.com/transcribe-tool/transcripts/preExport/"+id+".json"
  // const url = "https://podstacks.s3.amazonaws.com/transcribe-tool/transcripts/preExport/cd7f461f984c4ac796b21a8275802509.json"

  return fetch(url)
    .then( response => response.json() )
    // .then( stringifiedData => data = stringifiedData)
    // .then( stringifiedData => data = JSON.parse(stringifiedData) )
    .catch( error => console.log(`getTsxObj error: ${error}`) );
}