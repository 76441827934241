// REACT AND GATSBY IMPORTS
import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
// STYLING
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasClassic/podstacksUsers.style';
import '@redq/reuse-modal/es/index.css';
import { saasClassicTheme } from 'common/src/theme/saasClassic';    // Should make my own at some point
import { row } from './default.styles'
// CONTAINERS
import Sticky from 'react-stickynode';
import Navbar from '../containers/SaasClassic/Navbar'
// import UsersNavbar from '../containers/SaasClassic/UsersNavbar';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
// COMPONENTS
import Spacer from 'common/src/components/Spacer';
import SEO from './seo';

// ELEMENTS
import Box from 'reusecore/src/elements/Box'
// import Newsletter from '../containers/SaasClassic/Newsletter';
// import Footer from '../containers/SaasClassic/Footer';
// LOGIC

// Default (non-user/non-account holder) page layout template
const DefaultLayout = ({ children, seo, heading }) => {
  // console.log ({ isLoginPage, isInitialLogin, isValidUser })
  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO {...seo}/>
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          {/* Conditionally display content */}
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>
          {/* // Spacer (kind of a hack...) */}
          <Spacer />

          { (heading) ?
          <Box {...row}>
            <h2>{heading}</h2>
          </Box>:<></>
          }

          {/* // Main content */}
          {children}
          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default DefaultLayout;
